/*$(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000);
                
                return false;
            }
        }
    });
});
*/
var selectedCategory;

var $grid = $('.items').isotope({
    itemSelector: '.item',
    getSortData: {
        selectedCategory: function( itemElem ) {
            return $( itemElem ).hasClass( selectedCategory ) ? 0 : 1;
        }
    }
});

var $items = $('.items').find('.item');

$('.filters').on( 'click', '.filter', function(e) {
    // set selectedCategory
    selectedCategory = $( this ).attr('data-category');
    e.preventDefault();
    
    if ( selectedCategory == 'all' ) {

        $('html, body').animate({
            scrollTop: $("#top").offset().top
        }, 1000);
        
        $grid.isotope({
            sortBy: 'original-order'
        });
        
        // restore all items to full opacity
        $items.css({
            opacity: 1
        });

        return;
    }


    $('html, body').animate({
        scrollTop: $("#posts").offset().top
    }, 1000);

    // change opacity for selected/unselected items
    var selectedClass = '.' + selectedCategory;
    
    $items.filter( selectedClass ).css({
        opacity: 1
    });
    
    $items.not( selectedClass ).css({
        opacity: 0.25
    });

    // update sort data now that selectedCategory has changed
    $grid.isotope('updateSortData');
    $grid.isotope({ sortBy: 'selectedCategory' });
});

// change is-checked class on buttons
$('.filters').each( function( i, buttonGroup ) {
    var $buttonGroup = $( buttonGroup );
    
    $buttonGroup.on( 'click', 'a', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $( this ).addClass('is-checked');
    });
});